/* WarrantyRegistration.css */

@keyframes highlight {
    0% {
      background-color: #FFAAAA; /* darker red */
    }
    100% {
      background-color: #FFDDDD; /* lighter red */
    }
  }
  
  .error-message {
    animation: highlight 2s linear forwards;
    background-color: #FFDDDD; /* lighter red */
  }
  